import { Container, Row, Col } from 'react-bootstrap'
import Jumbotron from 'react-bootstrap/Jumbotron'

function ComingSoon() {
    return (
        <Container fluid>
            <Row>
                <Col>
                    <Jumbotron className="comingSoon">
                        <div>
                            <h1>Coming Soon...</h1>
                            <p>There will be a site here soon.</p>
                        </div>
                    </Jumbotron>
                </Col>
            </Row>
        </Container>
    );
}

export default ComingSoon;