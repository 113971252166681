import { Container, Row, Col } from 'react-bootstrap'
import Header from './header'
import SiteLink from './siteLink'

function Home() {
    return (
        <Container>
            <Row>
            <Col>
                <Header />
            </Col>
            </Row>
            <Row>
            <Col>
                <SiteLink 
                    link="https://carson.tolleshaug.org"
                    imageName="carson.svg" 
                    name="Carson" 
                    blurb="XP Full-Stack Software Engineer" />
            </Col>
            <Col>
                <SiteLink 
                    link="http://wedding.tolleshaug.org"
                    imageName="wedding.webp" 
                    name="Wedding" 
                    blurb="Click here to be taken to our wedding site." />
            </Col>
            <Col>
                <SiteLink 
                    link="/coming-soon"
                    imageName="lindsay.svg" 
                    name="Lindsay" 
                    blurb="Marketing Specialist" />
            </Col>
            </Row>
        </Container>
    );
}

export default Home;