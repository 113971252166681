import './App.css';
import { BrowserRouter, Route } from 'react-router-dom'
import Home from "./components/home"
import ComingSoon from './components/comingSoon';

function App() {
  return (
    <BrowserRouter>
      <Route path="/" exact component={Home} />
      <Route path="/coming-soon" exact component={ComingSoon} />
    </BrowserRouter>
  );
}

export default App;
