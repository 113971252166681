function SiteLink(props) {
    const siteLinkClick = () => {
        window.location = props.link
    };

    return (
        <div className="sitelink" onClick={ siteLinkClick }>
            <div className="siteimg">
                <img src={ "images/" + props.imageName } alt={ "Image of " + props.name } width="300px" height="300px" />
            </div>
            <div className="sitetext">
                <h2>{ props.name }</h2>
                <p>{ props.blurb }</p>
            </div>
        </div>
    );
}

export default SiteLink;